import { render, staticRenderFns } from "./News.vue?vue&type=template&id=9cb4b084&scoped=true&"
import script from "./News.vue?vue&type=script&lang=js&"
export * from "./News.vue?vue&type=script&lang=js&"
import style0 from "./News.vue?vue&type=style&index=0&id=9cb4b084&scoped=true&lang=css&"
import style1 from "./News.vue?vue&type=style&index=1&id=9cb4b084&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9cb4b084",
  null
  
)

export default component.exports