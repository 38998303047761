<template>
    <div class="error-container">
        <img
            class="err-back"
            src="http://cdn.sjtuair.com/6694b050-3367-11ea-abd0-69bd09a4c42b.png"
            alt=""
            v-if="show"
        />
        <img
            class="err-back"
            src="http://cdn.sjtuair.com/dd5d2dd0-4bd5-11ea-a8dd-9bdffaba508a.png"
            v-else
        />

        <div class="text">{{ text }}</div>
        <div class="btn-container">
            <div class="back click-op" @click="goTo">
                {{ "返回首页" | language }}
            </div>
            <div class="refresh click-op" @click="go">
                {{ "刷新页面" | language }}
            </div>
        </div>
    </div>
</template>

<script>
import { languageTranslation } from "../assets/util/language";
export default {
    props: {
        text: {
            default: `Error，${languageTranslation(
                "页面出错啦"
            )}！！ ${languageTranslation("请点击返回首页或者刷新页面按钮")}~`,
            type: String,
        },
        show: {
            default: true,
            type: Boolean,
        },
    },
    methods: {
        goTo() {
            this.$router.replace("/");
        },
        go() {
            this.$router.go(0);
        },
    },
};
</script>

<style lang="less" scoped>
.error-container {
    width: 460px;
    margin: 40px auto;

    .err-back {
        width: 100%;
        // height: 403px;
    }
    .text {
        margin-top: 40px;
        font-size: 24px;
        line-height: 42px;
        // text-align: left;
        color: #333;
        font-weight: 400;
    }
    .btn-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 40px auto 0;
        > div {
            width: 124px;
            height: 46px;
            cursor: pointer;
        }
        .back {
            border: 2px solid #1a3c90;
            font-size: 20px;
            text-align: center;
            font-weight: 500;
            color: #333;
            line-height: 46px;
            border-radius: 30px;
        }
        .refresh {
            background-image: url("http://cdn.sjtuair.com/3ef0e280-4bd6-11ea-a8dd-9bdffaba508a.png");
            background-position: 0 0;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            color: #fff;
            line-height: 46px;
            font-size: 20px;
            text-align: center;
            font-weight: 500;
        }
    }
}
@media screen and (max-width: 960px) {
    .error-container {
        width: 100%;
        padding: 0 8vw;
        .err-back {
            height: auto;
        }
        .text {
            font-size: 16px;
            line-height: 1.5;
        }
        .btn-container {
            width: 100%;
            justify-content: space-around;
            > div {
                width: auto;
                height: 35px;
                padding: 0px 10px;
            }
            .refresh,
            .back {
                line-height: 35px;
                font-size: 16px;
                font-weight: 400;
            }
        }
    }
}
</style>
