<template>
    <div class="news">
        <div class="search-bar">
            <Input
                suffix="ios-search"
                :placeholder="'搜索共建资讯' | language"
                style="width: 260px;margin-top:10px;"
                v-model="keyword"
                clearable
                @keyup.enter.native="handlePageChange(1)"
                size="large"
            />
        </div>
        <div class="container">
            <div class="list" v-if="list.length">
                <div class="item" v-for="item in list" :key="item._id">
                    <div class="item-left">
                        <div class="time">{{ item.writeAt }}</div>
                        <div class="title click-op" @click="goTo(item._id)">
                            {{ item.title }}
                        </div>
                        <div class="content row4-ellipse">
                            {{ item.excerpt }}
                        </div>
                    </div>
                    <img
                        class="item-src click-op"
                        v-real-img="item.cover.src"
                        @click="goTo(item._id)"
                        alt=""
                    />
                </div>
            </div>
            <error v-else></error>
            <div class="flex"></div>
            <Page
                :total="total"
                :current="pageNum"
                :page-size="pageSize"
                @on-change="handlePageChange"
                style="margin-top:40px;margin-bottom:40px;"
                show-total
            >
                <span class="ivu-page-total" style="font-size:16px;"
                    >{{ "共有" | language }} {{ total }}
                    {{ "条" | language }}</span
                >
            </Page>
        </div>
    </div>
</template>

<script>
import Error from "../../compontents/Error.vue";
export default {
    components: {
        Error
    },
    data() {
        return {
            list: [],
            pageSize: 10,
            pageNum: 1,
            total: 0,
            keyword: ""
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        goTo(id) {
            this.$router.push(`/base/cooperation/news/detail/${id}`);
        },
        //分页
        handlePageChange(page) {
            this.pageNum = page;
            this.fetchData();
        },
        async fetchData() {
            let res = await this.$http.get(
                `/cooperation/news?keyword=${this.keyword}&pageSize=${
                    this.pageSize
                }&pageNum=${this.pageNum - 1}`
            );

            this.list = res.result;
            this.total = res.count;
        }
    }
};
</script>
<style scoped>
.ivu-input {
    border-radius: 19px;
}
</style>
<style lang="less" scoped>
.news {
    .search-bar {
        margin-bottom: 40px;
        display: flex;
        justify-content: flex-end;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    }
    .container {
        display: flex;
        flex-direction: column;
        min-height: 1000px;

        .list {
            .item {
                margin-bottom: 40px;
                display: flex;
                align-items: flex-start;
                .item-left {
                    width: 743px;
                    margin-right: 20px;
                    .time {
                        margin-bottom: 10px;
                        text-align: left;
                        opacity: 0.3;
                        font-size: 16px;
                        color: #000000;
                        letter-spacing: 0;
                    }
                    .title {
                        margin-bottom: 15px;
                        text-align: left;
                        opacity: 0.9;

                        font-size: 22px;
                        color: #000000;
                        letter-spacing: 0;
                        line-height: 32px;
                    }
                    .content {
                        text-align: left;
                        width: 100%;
                        height: 116px;
                        opacity: 0.6;
                        font-size: 16px;
                        color: #000000;
                        letter-spacing: 0;
                        line-height: 29px;
                    }
                }
                .item-src {
                    width: 300px;
                    height: 200px;
                    object-fit: cover;
                }
            }
        }
        .flex {
            flex: 1;
        }
    }
}
@media screen and (max-width: 960px) {
    .news {
        .search-bar {
            justify-content: center;
        }
        .container {
            .list {
                .item {
                    flex-direction: column;
                    .item-left {
                        width: 100%;
                        margin-right: 0;
                    }
                    .item-src {
                        width: 100%;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}
</style>
